<template>
    <div class="case-bg-box">
        <div class="case-tel-box">
            <div class="case-search-content-tel p-b-20 p-t-20">
                <div class="case-input-box-tel m-r-10">
                    <input type="text" placeholder="搜索项目名称" v-model="val" class="case-input-sty-tel ">
                </div>
                <div class="color-white f-14 case-input-searchbtn-tel" @click="getList()">搜索</div>
            </div>
        </div>
        <div class="case-tel-items-box m-t-20">
            <div class="case-tel-item-content" v-for="(item,index) in list" :key="index"  @click="itemsClick(item)">
                <img :src="item.thumbnail" class="w-100p">
                <div class="d-flex d-flex-between m-t-3p">
                    <div>{{item.name}}</div>
                    <div>行业：{{item.industry_name}}</div>
                </div>
                <div class="m-t-3p">类型：{{item.type_name}}</div>
                <div class="m-t-3p">项目介绍：{{item.brief_introduction}}</div>
            </div>
        </div>
        <div class="w-100p h-180"></div>
    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'

export default defineComponent({
    setup(){
        const router = useRouter()
        const val = ref('')
        const platformActive = ref(0)
        const platforms = ref([])
        const typesActive = ref(0)
        const list = ref([])
        const types = ref([])

        const platformClick = (key)=>{
            platformActive.value = key
        }
         const typesClick = (key)=>{
            typesActive.value = key
        }
        const itemsClick = (item) =>{
            router.push({
                path:"/caseDetail",
                query:{
                    id:item.id
                }
            })
        }
        const getType = () =>{
            baseRequest.post({
                url: 'api/index/type_list',
            }).then(res=>{
                console.log(res,1)
                platforms.value = res.data
            })
            baseRequest.post({
                url: 'api/index/industry_list',
            }).then(res=>{
                console.log(res,2)
                types.value = res.data
            })
        }
        const getList = (typeId = 0,inindustryId = 0) =>{
            baseRequest.post({
                url: 'api/index/case_list',
                data:{
                    name:val.value,
                    type_id:typeId,
                    industry_id:inindustryId,
                    offest:100,
                    page:1
                }
            }).then(res=>{
                console.log(res,3)
                list.value = res.data.data
            })
        }
        getType()
        getList()
        return{
            platforms,platformActive,platformClick,types,typesActive,typesClick,itemsClick,val,list,getList
        }
    }
});
</script>


<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>

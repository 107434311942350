<template>
    <div class="group-bg-box">
        <div class="case-search-box m-t-20">
            <!-- <div class="b-b case-search-content p-b-20">
                <div class="case-input-box m-r-10">
                    <input type="text" placeholder="搜索项目名称" v-model="val" class="case-input-sty ">
                </div>
                <div class="color-white f-14 case-input-searchbtn" @click="getList(platforms[platformActive].id,types[typesActive].id)">搜索</div>
            </div> -->
            <div class="b-b case-search-content p-b-20 p-t-20">
                <div class="color-white f-14 m-r-25">按类型</div>
                <div class="d-flex color-white f-14">
                    <div class="case-text" :class="{'case-active-text':platformActive == index}"
                     v-for="(item,index) in platforms" :key="index"
                     @click="platformClick(index)">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="b-b case-search-content p-b-20 p-t-20">
                <div class="color-white f-14 m-r-25">按行业</div>
                <div class="d-flex color-white f-14">
                    <div class="case-text" :class="{'case-active-text':typesActive == index}"
                     v-for="(item,index) in types" :key="index"
                     @click="typesClick(index)">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="case-box m-t-20">
            <div class="case-content" v-for="(item,index) in list" :key="index" @click="itemsClick(item)">
                <img :src="item.thumbnail" class="w-100p">
                <div class="d-flex d-flex-between m-t-3p">
                    <div>{{item.name}}</div>
                    <div>行业：{{item.industry_name}}</div>
                </div>
                <div class="m-t-3p">类型：{{item.type_name}}</div>
                <div class="m-t-3p text-2">项目介绍：{{item.brief_introduction}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'

export default defineComponent({
    setup(){
        const router = useRouter()
        const val = ref('')
        const platformActive = ref(0)
        const platforms = ref([])
        const typesActive = ref(0)
        const list = ref([])
        const types = ref([])

        const platformClick = (key)=>{
            platformActive.value = key
            getList(platforms.value[platformActive.value].id,types.value[typesActive.value].id)
        }
         const typesClick = (key)=>{
            typesActive.value = key
            getList(platforms.value[platformActive.value].id,types.value[typesActive.value].id)
        }
        const itemsClick = (item) =>{
            router.push({
                path:"/caseDetail",
                query:{
                    id:item.id
                }
            })
        }
        const getType = () =>{
            baseRequest.post({
                url: 'api/index/type_list',
            }).then(res=>{
                console.log(res,1)
                platforms.value = res.data
            })
            baseRequest.post({
                url: 'api/index/industry_list',
            }).then(res=>{
                console.log(res,2)
                types.value = res.data
            })
        }
        const getList = (typeId = 0,inindustryId = 0) =>{
            baseRequest.post({
                url: 'api/index/case_list',
                data:{
                    name:val.value,
                    type_id:typeId,
                    industry_id:inindustryId,
                    offest:100,
                    page:1
                }
            }).then(res=>{
                console.log(res,3)
                list.value = res.data.data
            })
        }
        getType()
        getList()
        return{
            platforms,platformActive,platformClick,types,typesActive,typesClick,itemsClick,val,list,getList
        }
    }
});
</script>
<style scoped>
.text-2{
    overflow:hidden;

text-overflow:ellipsis;

display:-webkit-box;

-webkit-box-orient:vertical;

-webkit-line-clamp:2;
}
</style>
